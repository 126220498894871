.element-contentmedia {
  &.as-media-left {
    .media-container {
      @apply flex-shrink-0 md:w-1/2 md:pr-12 xl:pr-16;
    }

    .content-container {
      @apply lg:px-8 xl:px-12 2xl:pl-16 2xl:pr-20;
    }
  }

  &.as-media-left-fluid {
    .media-container {
      @apply md:max-w-[50vw] md:pr-14 xl:pr-24 2xl:pr-36;
    }
  }

  &.as-media-right {
    .media-container {
      @apply flex-shrink-0 md:order-last md:w-1/2 md:pl-12 xl:pl-16;
    }

    .content-container {
      @apply pl-0 lg:pr-8 xl:pr-12 2xl:pr-16;
    }
  }

  &.as-media-right-fluid {
    .media-container {
      @apply md:order-last md:max-w-[50vw] md:pl-14 xl:pl-24 2xl:pl-36;
    }
  }
}
